"use strict";

require("core-js/modules/es.array.iterator.js");

require("core-js/modules/es.array.map.js");

require("core-js/modules/es.function.name.js");

require("core-js/modules/es.map.js");

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/es.string.iterator.js");

require("core-js/modules/web.dom-collections.iterator.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _client = require("@apollo/client");

var _locales = require("@meepshop/locales");

var _react = require("react");

var _getFilteredAddressServiceSchema = require("../gqls/getFilteredAddressServiceSchema");

// typescript import
var getDisabledMap = function getDisabledMap(currMap, option) {
  for (var i = 0; i < option.length; i += 1) {
    currMap.set(option[i].value, option[i].disabled);

    if (option[i].children) {
      getDisabledMap(currMap, option[i].children);
    }
  }

  return currMap;
};

var getValueEzcatDisabledMap = function getValueEzcatDisabledMap(option) {
  var map = new Map();
  return getDisabledMap(map, option);
};

var TAIWAN_ID = 'a1e4aa6c-5a52-408a-9ede-471b10b1e265';

var useGetFilteredAddressService = function useGetFilteredAddressService(_ref) {
  var shipmentId = _ref.shipmentId,
      isEzcat = _ref.isEzcat;
  var getLanguage = (0, _locales.useGetLanguage)();

  var _useQuery = (0, _client.useQuery)(_getFilteredAddressServiceSchema.GET_FILTERED_ADDRESS_SERVICE, {
    variables: {
      shipmentId: shipmentId || ''
    },
    skip: !isEzcat || !shipmentId
  }),
      data = _useQuery.data;

  var countries = (0, _react.useMemo)(function () {
    return (data === null || data === void 0 ? void 0 : data.filteredAddressService.countries) || [];
  }, [data]);
  var cities = (0, _react.useMemo)(function () {
    return (data === null || data === void 0 ? void 0 : data.filteredAddressService.cities) || [];
  }, [data]);
  var addressOptions = (0, _react.useMemo)(function () {
    return countries.map(function (country) {
      return {
        value: country === null || country === void 0 ? void 0 : country.id,
        label: getLanguage(country === null || country === void 0 ? void 0 : country.name),
        disabled: (country === null || country === void 0 ? void 0 : country.ezcatBlocked) || false,
        children: (country === null || country === void 0 ? void 0 : country.id) === TAIWAN_ID ? (cities || []).map(function (city) {
          return {
            value: city.id,
            label: getLanguage(city.name),
            disabled: city.ezcatBlocked || false,
            children: city.areas.map(function (area) {
              return {
                value: area.id,
                label: getLanguage(area.name),
                zipCodes: area.zipCodes,
                disabled: area.ezcatBlocked || false
              };
            })
          };
        }) : undefined
      };
    });
  }, [countries, cities, getLanguage]);
  return {
    addressOptions: addressOptions,
    ezcatDisabledMap: getValueEzcatDisabledMap(addressOptions) // 紀錄地址 id 是否不被黑貓運送 {'XXX' => true} 代表 id: XXX 不可被運送

  };
};

var _default = useGetFilteredAddressService;
exports["default"] = _default;