"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.map.js");

require("core-js/modules/es.array.some.js");

require("core-js/modules/es.function.name.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _react = require("react");

var _locales = require("@meepshop/locales");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

// definition
var getOptions = function getOptions(data, getLanguage) {
  return data.map(function (_ref) {
    var id = _ref.id,
        name = _ref.name,
        children = _ref.children,
        d = (0, _objectWithoutProperties2["default"])(_ref, ["id", "name", "children"]);
    return _objectSpread(_objectSpread({}, d), {}, {
      value: id,
      label: getLanguage(name),
      children: !children || children.length === 0 ? undefined : getOptions(children, getLanguage)
    });
  });
};

var _default = function _default(_ref2, shippableCountries) {
  var countries = _ref2.countries;
  var getLanguage = (0, _locales.useGetLanguage)();
  return (0, _react.useMemo)(function () {
    return getOptions( // TODO: should use shippableCountries directly, remove countries
    shippableCountries.length === 0 ? countries : countries.map(function (c) {
      var disabled = !shippableCountries.some(function (_ref3) {
        var shippableCountryId = _ref3.id;
        return shippableCountryId === c.id;
      });
      return _objectSpread(_objectSpread({}, c), {}, {
        disabled: disabled
      }); // 不能寄送的加入 disabled = true, 可以寄送的加入 disabled = false，這樣可以讓 cascader 元件隱藏選項，但又可以選得到（因為有時選擇收件人資料會有不可以選的選項出現，但是又要顯示該選項並跳出提示訊息）
    }), getLanguage);
  }, [shippableCountries, countries, getLanguage]);
};

exports["default"] = _default;