"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.find.js");

require("core-js/modules/es.array.slice.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _toArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toArray"));

var _react = require("react");

// graphql typescript
// import
// definition
var findZipCodes = function findZipCodes(options, address) {
  var _ref = address || [],
      _ref2 = (0, _toArray2["default"])(_ref),
      id = _ref2[0],
      otherIds = _ref2.slice(1);

  var option = options.find(function (_ref3) {
    var value = _ref3.value;
    return value === id;
  });
  var zipCodes = option === null || option === void 0 ? void 0 : option.zipCodes;
  if (zipCodes) return zipCodes;
  if (!option || otherIds.length === 0) return null;
  return findZipCodes(option.children || [], otherIds);
};

var _default = function _default(options, address) {
  return (0, _react.useMemo)(function () {
    return findZipCodes(options, address);
  }, [options, address]);
};

exports["default"] = _default;