"use strict";

require("core-js/modules/es.array.filter.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = require("react");

// import
// definition
var _default = function _default(options, value, onChange) {
  var _ref = value || {},
      _ref$address = _ref.address,
      address = _ref$address === void 0 ? undefined : _ref$address,
      _ref$zipCode = _ref.zipCode,
      zipCode = _ref$zipCode === void 0 ? undefined : _ref$zipCode,
      _ref$label = _ref.label,
      label = _ref$label === void 0 ? [] : _ref$label;

  (0, _react.useEffect)(function () {
    var _options$;

    if (onChange && options && (options === null || options === void 0 ? void 0 : options.length) === 1 && (address || []).length === 0 && !((_options$ = options[0]) !== null && _options$ !== void 0 && _options$.children)) {
      onChange({
        address: [options[0].value],
        zipCode: zipCode,
        label: label
      });
    }
  }, [address, zipCode, onChange, options, label]);
  return {
    address: address === null || address === void 0 ? void 0 : address.filter(Boolean),
    zipCode: zipCode,
    label: label
  };
};

exports["default"] = _default;