"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GET_FILTERED_ADDRESS_SERVICE = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _client = require("@apollo/client");

var _locale = require("@meepshop/utils/lib/gqls/locale");

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  query getFilteredAddressService($shipmentId: ID!) {\n    filteredAddressService(shipmentId: $shipmentId) {\n      countries {\n        id\n        name {\n          ...localeFragment\n        }\n        ezcatBlocked\n      }\n      cities {\n        id\n        name {\n          ...localeFragment\n        }\n        ezcatBlocked\n\n        areas {\n          id\n          name {\n            ...localeFragment\n          }\n          zipCodes\n          ezcatBlocked\n        }\n      }\n    }\n  }\n\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

// definition
var GET_FILTERED_ADDRESS_SERVICE = (0, _client.gql)(_templateObject(), _locale.localeFragment);
exports.GET_FILTERED_ADDRESS_SERVICE = GET_FILTERED_ADDRESS_SERVICE;