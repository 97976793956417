"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useOptionsAddressServiceFragment = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _client = require("@apollo/client");

var _locale = require("@meepshop/utils/lib/gqls/locale");

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  fragment useOptionsAddressServiceFragment on AddressService {\n    countries {\n      id\n      name {\n        ...localeFragment\n      }\n\n      children: cities {\n        id\n        name {\n          ...localeFragment\n        }\n\n        children: areas {\n          id\n          name {\n            ...localeFragment\n          }\n          zipCodes\n        }\n      }\n    }\n  }\n\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

// definition
var useOptionsAddressServiceFragment = (0, _client.gql)(_templateObject(), _locale.localeFragment);
exports.useOptionsAddressServiceFragment = useOptionsAddressServiceFragment;